import React from "react"
import "../assets/css/contact.css"
import Footer from '../components/footer';
import NewsLatter from '../components/newsletter';
import Formsy from 'formsy-react';
import { connect } from 'react-redux';
// import { navigate } from "gatsby"

import lineImage from "../images/line2.png"

import Layout from "../components/layout"

import banner from "../images/contacts/contact-us-hero.png"
import handIcon from "../images/hand.svg"
import chatbottom from "../images/chat-bottom-icon.svg"
import chatProfile from "../images/chat-profile.png"
import closeChat from "../images/close-icon.svg"
import callicon from "../images/phone-icon.svg"
import mailicon from "../images/mail-icon.svg"
import contacticon from "../images/contacts/contactCloud.svg"

import SEO from "../components/seo"
import MyInput from "../components/MyInput";
import * as actionCreators from '../store/actions/index';
import ButtonLoader from "../components/buttonLoader";
import ErrrorHandel from "../components/ErrrorHandel";


class ContactPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            canSubmit: false,
        };
    }
    

    disableButton = () => {
        this.setState({ canSubmit: false });
    }

    enableButton = () => {
        this.setState({ canSubmit: true });
    }

    submit = async(model) => {
        const { showButtonLoader, hideButtonLoader, showSuccessPopup } = this.props;
        showButtonLoader();
        let uri = process.env.GATSBY_APP_API_BASE_URL+'rest/contact'+(global.dealertoken?'/?dealertoken='+global.dealertoken:'');
        await fetch(uri,{
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(model)
        })
        .then(res => res.json())
        .then((res) => {
            const payload = {
                message: 'Your inquiry has been successfully submitted.',
                btnLabel: 'Ok'
            }
            showSuccessPopup(payload);
            this.refs['contactusForm'].reset();
            hideButtonLoader();
        });
        
    }

    render() {
        const { canSubmit } = this.state;
        const { buttonLoader } = this.props;
        
        return (
            <Layout rest={this.props} layoutType="main">
                <SEO title="Servicecontract.com - Contact" keywords={[`servicecontract.com`, `service contracts`, `Extended warranty`]} />
                <div className="chat-content" style={{ display: 'none' }}>
                    <div className="chat-content-circle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="26.496" viewBox="0 0 30 26.496">
                            <g id="Group_157" data-name="Group 157" transform="translate(-309.85 -157.354)">
                                <g id="Group_27" data-name="Group 27" transform="translate(309.85 157.354)">
                                    <path id="Path_73" data-name="Path 73" d="M309.85,170.605c0,7.3,6.733,13.245,15,13.245a16.468,16.468,0,0,0,7.738-1.9,8.731,8.731,0,0,0,4.123.967,10.089,10.089,0,0,0,1.427-.1,1.14,1.14,0,0,0,.46-2.089,4.986,4.986,0,0,1-1.8-2.132,12.3,12.3,0,0,0,3.045-8c0-7.3-6.733-13.245-15-13.245S309.85,163.3,309.85,170.605Zm1.5,0c0-6.476,6.058-11.744,13.5-11.744s13.5,5.269,13.5,11.744a10.721,10.721,0,0,1-2.971,7.345.756.756,0,0,0-.153.772,6.667,6.667,0,0,0,1.795,2.7,7.254,7.254,0,0,1-4.044-.968.717.717,0,0,0-.386-.1.745.745,0,0,0-.373.1,14.929,14.929,0,0,1-7.37,1.911C317.409,182.349,311.351,177.081,311.351,170.605Z" transform="translate(-309.85 -157.354)" fill="#fff" />
                                    <circle id="Ellipse_1" data-name="Ellipse 1" cx="1.924" cy="1.924" r="1.924" transform="translate(13.085 11.327)" fill="#fff" />
                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="1.924" cy="1.924" r="1.924" transform="translate(17.943 11.327)" fill="#fff" />
                                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="1.924" cy="1.924" r="1.924" transform="translate(8.227 11.327)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="chat-box">

                        <div className="chatbox-inner">
                            <div className="chat-profile">
                                <img className="" src={chatProfile} alt="" />
                            </div>
                            <span className="close-chat"><img className="" src={closeChat} alt="" /></span>
                            <p> <img className="hand-icon" src={handIcon} alt="" /> <span>Hi, We're here to help.</span></p>
                        </div>
                        <img className="chat-bottom" src={chatbottom} alt="" />
                    </div>

                </div>

                {/* --------------banner--------- */}


                {/* <section className="banner contact-banner">
                    <div className="banner">
                        <div className="container">
                            <div className="contect-inner">
                                <div className="contact-image">
                                    <img src={contactBanner} alt="" />
                                </div>
                                <div className="banner-text">
                                    <h1>We are here to help</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="banner">
                <h1>We are here to help</h1>
                {/* <h5>Rely on us for our expertise. We are easy to deal with.</h5>
                <img src={banner} alt="" className="newbaner" /> */}
                </section>

                {/* <section className="heading">
                    <div className="container">
                        <h1 className="heading t-center"></h1>
                    </div>
                </section> */}

                <section className="formpart">
                    <div className="container">
                        <div className="contact-form">
                            
                            <div className="right-side">
                                

                                    <img src={contacticon} alt="mail-icon" className="contacticon" />

                                    <div className="chat-icon-1">
                                        
                                        <h1 className="detail"><img src={mailicon} alt="call-iocn" /> help@servicecontract.com</h1>
                                        <p className="sub-text">24/7 - Available when you need us</p>
                                    </div>
                                    <div className="chat-icon-1">
                                        
                                        <h1 className="detail"><img src={callicon} alt="call-iocn" /> 1-800-800-3629</h1>
                                    </div>
                                
                            </div>

                            <div className="left-side">
                                <Formsy ref="contactusForm" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                                <ErrrorHandel formName={'contactusForm'} self={this} />
                                    <h1>Contact us today</h1>
                                    <div className="form-group error">
                                        <MyInput
                                            name="name"
                                            id="name"
                                            placeholder="Your name"
                                            type="text"
                                            value={''}
                                            isLabelRequired={false}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className="form-group error">
                                        <MyInput
                                            name="email"
                                            id="email"
                                            placeholder="Your email"
                                            type="text"
                                            validations="isEmail"
                                            validationError="Please enter valid email"
                                            value={''}
                                            isLabelRequired={false}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <div className="form-group error">
                                        <MyInput
                                            name="message"
                                            id="textarea"
                                            placeholder="How can we help?"
                                            type="text"
                                            value={''}
                                            isLabelRequired={false}
                                            className="form-control"
                                            required
                                        />
                                    </div>
                                    <p>By submitting, you give consent for your contact information to be used to reply to your request.</p>
                                    <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'disabled button-send' : 'button-send'}>
                                        <ButtonLoader isLoading={(canSubmit && buttonLoader)?true:false} label={'SUBMIT'} />
                                    </button>
                                    </Formsy>
                                </div>
                        </div>
                    </div>
                </section>
                {/* <NewsLatter /> */}
                <div className="break-section">
                    <img src={lineImage} alt="line" />
                </div>
                <Footer />
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        pageLoader: state.primary.pageLoader,
        buttonLoader: state.primary.buttonLoader,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        showLoader: () => dispatch(actionCreators.showLoader()),
        showButtonLoader: () => dispatch(actionCreators.showButtonLoader()),
        hideLoader: () => dispatch(actionCreators.hideLoader()),
        hideButtonLoader: () => dispatch(actionCreators.hideButtonLoader()),
        showSuccessPopup: (payload) => dispatch(actionCreators.showSuccessPopup(payload)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactPage);
